import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from '../components/layout'
import SEO from '../components/seo'

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title,
          description
        }
      }
    }
  `)
  const { site: { siteMetadata: { title, description } }} = data
  return ( 
    <Layout>
      <SEO title="Kirsantov" />
      <h1>{title}</h1>
      <p>{description}</p>
      <ul>
        <li><Link to="/about">About</Link></li>
        <li><Link to="/uses">Uses</Link></li>
      </ul>
    </Layout>
  )
 }